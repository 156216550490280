import Instance from "./instance";

export const GetDetails = async () => {
  try {
    return await Instance.instanceToken.get(`campaign/settings`);
  } catch (error) {
    return error;
  }
};

export const SetupStripe = async () => {
  try {
    return await Instance.instanceToken.get(`payments/setupStripe`);
  } catch (error) {
    return error;
  }
};

export const LoginStripe = async () => {
  try {
    return await Instance.instanceToken.get(`payments/loginStripe`);
  } catch (error) {
    return error;
  }
};

export const PaySetupFee = async () => {
  try {
    return await Instance.instanceToken.get(`payments/setupFee`);
  } catch (error) {
    return error;
  }
};

export const CreateAdditionalInfo = async (payload) => {
  try {
    return await Instance.instanceToken.post(`/campaignInformation`, payload);
  } catch (error) {
    return error;
  }
};

export const RetainAccount = async () => {
  try {
    return await Instance.instanceToken.post(`/campaignInformation/retain`);
  } catch (error) {
    return error;
  }
};

export const GetAdditionalInfo = async () => {
  try {
    return await Instance.instanceToken.get(`/campaignInformation`);
  } catch (error) {
    return error;
  }
};

export const ReturningFromPayment = async (data) => {
  try {
    return await Instance.instanceToken.post(`/payments/setupFeeUpdated`, data);
  } catch (error) {
    return error;
  }
};

export const GetOnboardingStatus = async () => {
  try {
    return await Instance.instanceToken.get(
      `/payments/paypal/onboardingStatus`
    );
  } catch (error) {
    return error;
  }
};

export const OrderCompleted = async (orderId) => {
  try {
    return await Instance.instanceToken.post("/payments/paypal/capture", {
      orderId,
    });
  } catch (error) {
    return error;
  }
};
export const GetCouponsDetails = async (code, orderId) => {
  try {
    return await Instance.instanceToken.post("/payments/paypal/applyCoupon", {
      code,
      orderId,
    });
  } catch (error) {
    return error;
  }
};

export const DisconnectPayPal = async () => {
  try {
    return await Instance.instanceToken.post("/payments/paypal/disconnect");
  } catch (error) {
    return error;
  }
};